@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  body {
    @apply text-tone-900 bg-pale;
  }
  a {
    @apply text-primary hover:text-primary-400 transition-colors;
  }
  .bg-blend {
    background-image: url("/src/assets/images/lp-blend-with-colour.png");
    @apply selection:bg-primary selection:text-white bg-pale bg-fixed bg-cover bg-no-repeat;
  }
  .selected {
    @apply text-primary !border-primary;
  }
  .button,
  a.button {
    @apply inline-flex items-center gap-2  px-8 py-2 rounded-md bg-white border-2 border-primary text-primary transition-colors;
  }
  .button-primary {
    @apply cursor-pointer text-center inline-flex px-10 py-2 rounded-full border bg-primary border-primary text-white hover:text-white font-medium items-center gap-2  hover:bg-primary-600 transition-colors;
  }
  .button-primary[disabled] {
    @apply cursor-not-allowed bg-gray-400 border-gray-400 text-gray-100;
  }

  .button-secondary {
    @apply text-center inline-flex px-10 py-2 rounded-full border bg-none border-primary-600 text-primary-600 hover:text-primary-600  font-medium items-center gap-2  hover:bg-primary-100 hover:bg-opacity-50 transition-colors;
  }

  .button-low {
    @apply inline-flex px-8  py-2 rounded-md bg-transparent items-center border-tone-700 text-tone-700;
  }
  .button-primary-disabled {
    @apply inline-block px-8 py-2 rounded-md bg-gray-200 border-gray-200 text-white cursor-default;
  }
  .button-icon {
    @apply inline-block border-tone-700 border rounded-full p-2 bg-transparent text-tone-700 transition-colors;
  }
  .button-icon:hover {
    @apply inline-block border-tone-900 text-tone-900;
  }
  .button-text {
    @apply inline-flex items-center gap-2 border-none rounded-full px-2 py-2 bg-transparent text-tone-700 transition-colors text-sm hover:text-tone-900 cursor-pointer;
  }
  .button-text[disabled] {
    @apply cursor-default hover:text-tone-700;
  }
  .button-link {
    @apply inline-flex items-center gap-2 border-none rounded-full px-2 py-2 bg-transparent text-primary transition-colors text-sm hover:text-primary-400 cursor-pointer;
  }
  .button-link[disabled] {
    @apply inline-flex items-center gap-2 border-none rounded-full px-2 py-2 bg-transparent text-tone-700 transition-colors text-sm cursor-default;
  }
  .token-field span.token {
    @apply bg-green-50 px-2 py-1 inline-flex justify-center border rounded-full text-xs;
  }
  .intro-text {
    @apply mt-4 text-lg font-thin;
  }

  .card {
    @apply bg-white flex rounded-xl p-4 relative border-tone-200 border;
  }
  .card-ghost {
    @apply transition-all bg-white bg-opacity-50 outline outline-4 outline-white hover:bg-opacity-70 rounded-2xl;
  }
  h1 {
    @apply text-4xl font-semibold;
  }
  h2 {
    @apply mx-auto my-3 text-2xl font-semibold;
  }
  h3 {
    @apply block text-lg font-medium;
  }

  label {
    @apply flex m-0  font-medium  group-focus-within:text-primary;
  }

  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="password"],
  input[type="date"],
  input,
  select {
    @apply text-gray-900 rounded-md  border-neutral-300 hover:border-neutral-400 font-normal focus:border-primary focus:ring-primary placeholder-neutral-300;
  }

  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml,%3csvg fill='%23E1E0DC' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    background-size: 20px 20px;
  }
  select:focus {
    background-image: url("data:image/svg+xml,%3csvg fill='%2330A689' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'/%3e%3c/svg%3e");
  }

  select.select-special {
    background-image: url("data:image/svg+xml,%3csvg fill='%2330A689' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'/%3e%3c/svg%3e");
  }

  input[id^="react-select"] {
    @apply focus:!border-none focus:!outline-none focus:!ring-0 !w-max;
  }

  body #hubspot-messages-iframe-container {
    @apply !z-[9998];
  }

  .bullets-tick {
    @apply list-none -mt-4;
  }
  .bullets-tick li {
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22%2330A689%22%20class%3D%22w-5%20h-5%22%3E%0A%20%20%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M10%2018a8%208%200%201%200%200-16%208%208%200%200%200%200%2016Zm3.857-9.809a.75.75%200%200%200-1.214-.882l-3.483%204.79-1.88-1.88a.75.75%200%201%200-1.06%201.061l2.5%202.5a.75.75%200%200%200%201.137-.089l4-5.5Z%22%20clip-rule%3D%22evenodd%22%20%2F%3E%0A%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: left;
    background-size: 1.5rem;
    padding-left: 1.5rem;
    @apply text-primary my-0 list-none;
  }

  .tooltip-container {
    @apply hover:cursor-help relative;
  }
  .tooltip {
    @apply max-w-[90%] h-auto absolute mx-auto top-[100%] mt-2 overflow-visible opacity-0 transition-all px-3 py-1 text-xs bg-tone-900 text-white rounded-md shadow-sm text-center group-hover:opacity-100;
  }
  .tooltip::before {
    content: "";
    @apply absolute left-[50%] transform translate-x-[-50%] top-[-10px] border-[5px] border-b-tone-900 border-t-transparent border-x-transparent;
  }
}
